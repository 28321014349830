<template>
  <div>
    <Header :self="self" />
    <div class="container main">
      <div class="alert alert-danger" role="alert" v-if="error">
        {{error.message}}
      </div>
      <div class="content">
        <p class="text-center gqp-header" v-if="!self.isPro">Like Quozio? You'll ❤️ Quozio Pro! <router-link :to="{name: 'get-quozio-pro', query: {ref: 'IXH'}}">Check it out</router-link>.</p>
        <form @submit.prevent="createQuote" :disabled="submitting.createQuote" class="pb-4">
          <div class="form-group" v-bind:class="{ 'form-group-error': $v.quote.quote.$error }">
            <textarea v-model="quote.quote" v-model.trim.lazy="$v.quote.quote.$model" class="form-control quote" placeholder="Enter your quote..." v-resizable maxlength="1500"></textarea>
            <div class="error-input">Please provide a quote</div>
          </div>
          <div class="form-group">
            <input type="text" v-model="quote.author" class="form-control author" placeholder="Who said it? (Optional)" />
          </div>
          <button class="btn btn-primary btn-quote-create" type="button" @click="createQuote()" :disabled="submitting.createQuote">Create My Quote</button>
        </form>
        <div class="text-center pb-4">
          <i class="far fa-lightbulb text-warning"></i> Need some inspiration? <span class="faux-link" @click="getRandomQuote()">Try a random quote</span>.
        </div>

        <p class="text-center">Quozio is the fastest way to create quotes to save and share. Making beautiful quotes just became easy! Try it yourself to see why millions choose Quozio as their favorite quote maker. It's free! <strong><router-link to="/resources/how-to">Learn more about Quozio</router-link></strong>.</p>

        <div v-if="featuredItems.data.length">
          <h5 class="text-center mt-2">Featured Quotes</h5>
          <div v-for="(featuredItem, index) in featuredItems.data" class="mb-4">
            <nuxt-link :to="{ name: 'item-boardId-itemId-itemSlug', params: { boardId: featuredItem.boardItem.boardId, itemId: featuredItem.boardItem.boardItemId, itemSlug: featuredItem.boardItem.slug } }">
              <img :src="featuredItem.boardItem.imageUrls.large" class="feed-item" />
            </nuxt-link>
            <div class="featured-quote" v-bind:class="{'featured-quote-faded': !shouldShowFeatured(featuredItem.featuredItemId)}" v-if="featuredItem.boardItem.caption">
              <strong><nuxt-link :to="{ name: 'username', params: { username: featuredItem.boardItem.user.username } }">{{featuredItem.boardItem.user.name}} (@{{featuredItem.boardItem.user.username}})</nuxt-link></strong> <span v-bind:class="{'caption-text': shouldShowFeatured(featuredItem.featuredItemId)}" v-html="featuredItem.boardItem.caption"></span>
              <p class="fadeout">&nbsp;</p>
            </div>
            <div class="text-center pt-1 pb-3" v-if="!shouldShowFeatured(featuredItem.featuredItemId) && featuredItem.boardItem.caption"><button class="btn btn-secondary btn-pill" @click="readMore(featuredItem.featuredItemId)">Read More</button></div>
          </div>
        </div>

        <div v-if="feedItems.data.length">
          <hr class="mt-5 mb-5" />
          <div v-for="(feedItem, index) in feedItems.data" class="pb-4 text-center">
            <nuxt-link :to="{ name: 'item-boardId-itemId-itemSlug', params: { boardId: feedItem.boardItem.boardId, itemId: feedItem.boardItem.boardItemId, itemSlug: feedItem.boardItem.slug } }">
              <img :src="feedItem.boardItem.imageUrls.large" class="feed-item" />
            </nuxt-link>
            <div class="d-flex justify-content-between">
              <div class="user-info text-left">Saved by <nuxt-link :to="{ name: 'username', params: { username: feedItem.boardItem.user.username } }">{{feedItem.boardItem.user.name}}</nuxt-link> to <nuxt-link :to="{ name: 'board-boardId-boardSlug', params: { boardId: feedItem.boardItem.board.boardId, boardSlug: feedItem.boardItem.board.slug } }">{{feedItem.boardItem.board.name}}</nuxt-link></div>
              <div class="text-right" style="min-width: 75px;">
                <button class="btn btn-success btn-sm mt-1" @click="openSave(feedItem.boardItem)" v-show="self.userId"><i class="fas fa-copy"></i> Save</button>
                <button class="btn btn-success btn-sm mt-1" v-show="!self.userId" v-b-modal.modal-create-account-save-quote><i class="fas fa-copy"></i> Save</button>
              </div>
            </div>
          </div>
        </div>

      </div>

      <b-modal id="modal-board-select" scrollable>
        <div class="alert alert-danger" role="alert" v-if="modalError">
          {{modalError.message}}
        </div>
        <template v-slot:modal-title>
          Where do you want to save it?
        </template>
        <div class="" v-if="!showCreateBoard">
          <div class="boards-select-wrapper justify-content-left">
            <div class="text-center mb-2">
              <button class="btn btn-primary btn-success btn-pill" @click="showCreateBoard = true">Create New Board</button>
            </div>
            <div v-for="(board, index) in boards.data" class="board-select-wrapper pointer" @click="copy(board.boardId)">
              <div class="board-select-icon">
                <img :src="board.previewImageUrls.square" />
              </div>
              <div class="board-select-text">
                {{board.name}}
                <div class="sub text-secondary">
                  <span>{{board.itemCount}} <span v-if="board.itemCount === 1">Quote</span><span v-if="board.itemCount !== 1">Quotes</span></span>
                  <span v-if="board.private" title="This board is private">&nbsp;<i class="fas fa-lock"></i></span>
                  <span v-if="board.hidden && !board.private" title="This board is hidden">&nbsp;<i class="fas fa-eye-slash"></i></span>
                  <span v-if="board.userCount > 1" title="This is a group board">&nbsp;<i class="fas fa-user-friends"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showCreateBoard">
          <h5>Create a new board</h5>
          <div class="form-group" v-bind:class="{ 'form-group-error': $v.newBoard.name.$error }">
            <label for="name">Name</label>
            <input type="text" v-model.trim.lazy="$v.newBoard.name.$model" class="form-control" id="name" placeholder="What do you want to call this board?">
            <div class="error-input">Please provide a name</div>
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="private" v-model="newBoard.private">
            <label class="form-check-label" for="private">Make this a private board</label>
            <small class="form-text text-muted">Private boards can only be seen and accessed by you and others you invite to the board.</small>
          </div>
          <div class="form-check" v-show="!newBoard.private">
            <input type="checkbox" class="form-check-input" id="hidden" v-model="newBoard.hidden">
            <label class="form-check-label" for="hidden">Don't show this board with my profile</label>
            <small class="form-text text-muted">Others will be able to access this board, but it will not be listed on your profile page.</small>
          </div>
        </div>
        <template v-slot:modal-footer v-bind:class="{ 'hide-modal-footer': showCreateBoard === false }">
          <div v-if="!showCreateBoard"></div>
          <div class="board-select-footer right pointer" v-if="showCreateBoard">
            <button class="btn btn-light btn-sm mb-2" @click="showCreateBoard = false">Cancel</button>
            <button class="btn btn-success btn-sm ml-2 mb-2" @click="createBoard()"><i class="fas fa-check"></i> Save</button>
          </div>
        </template>
      </b-modal>

      <b-modal id="modal-create-account-save-quote" title="Save Quote" hide-footer>
        <div class="alert alert-danger" role="alert" v-if="modalError">
          {{modalError.message}}
        </div>
        <p class="text-center"><i class="fas fa-file-import fa-5x text-primary"></i></p>
        <p class="my-4">
          <h5 class="text-center">Want to save this quote?</h5>
        </p>
        <p class="my-4 text-center">
          No problem! Join Quozio to keep your favorite quotes in a safe place, making them easy to find, share and download. It's free!
        </p>
        <p class="my-4 text-center">
          <router-link class="btn btn-success btn-lg" :to="{ name: 'join', query: { redirect: getRoutePath() } }">Join Now, It's Free!</router-link>
        </p>
        <p class="my-4 text-center">
          Already have an account? <router-link :to="{ name: 'sign-in', query: { redirect: getRoutePath() } }" v-cloak>Sign In</router-link>.
        </p>
      </b-modal>

    </div>
    <Footer />
  </div>
</template>

<script>
  import Header from "@/components/header";
  import Footer from "@/components/footer";
  import { mapState } from "vuex";
  import { required } from "vuelidate/lib/validators";
  const feedItemLimit = 10; // You need enough items that a scrollbar will be generated for the page so that new items will trigger when they scroll to the bottom.
  const featuredItemLimit = 3;
  let isScrolling;

  export default {
    components: { Header, Footer },
    data() {
      return {
        quote: { quote: "", author: "" },
        feedItems: { data: [] },
        featuredItems: { data: [] },
        boardItem: {
          board: {}
        },
        boards: { data: [] },
        showCreateBoard: false,
        newBoard: { name: null, private: false, hidden: false },
        loadingFeedItems: false,
        randomQuotes: [],
        showFeatured: [],
        error: null,
        modalError: null,
        submitting: { createQuote: false, copy: false, createBoard: false },
      };
    },
    async asyncData({ store, app, error }) {
      await store.dispatch("self/get", app.$cookies);
      let response = await app.$dataService.get("/featuredItems", { expand: "boardItem.user", limit: featuredItemLimit, desc: true });
      if (response.error) {
        return error({ statusCode: response.error.status, message: response.error.message, reference: response.error.reference });
        return;
      }
      return { featuredItems: response.data };
    },
    async created() {
      if (this.$route.query.quote) {
        this.quote.quote = this.$route.query.quote;
        this.quote.author = this.$route.query.author;
      }
    },
    head() {
      return {
        title: "Quozio - Make Beautiful Quotes",
        meta: [
          { hid: "description", name: "description", content: "Quozio turns meaningful words into beautiful images in seconds. Then share them on Facebook, Pinterest, email and more!" }
        ]
      }
    },
    async mounted() {
      await this.getInitialFeedItems();
      window.addEventListener("scroll", this.checkLoadFeedItems, false);
      this.$root.$on("bv::modal::hidden", (bvEvent, modalId) => {
        this.modalError = null;
        this.showCreateBoard = false;
      })
    },
    methods: {
      async createQuote() {

        this.$v.quote.$touch();
        if (this.$v.quote.$invalid) {
          return;
        }

        this.submitting.createQuote = true;
        this.$nuxt.$loading.start();
        let response = await this.$dataService.post("/quotes", this.quote);
        this.submitting.createQuote = false;
        if (response.error) {
          this.error = response.error;
          this.$nuxt.$loading.finish();
          return;
        }
        let newQuote = response.data;

        this.$nuxt.$loading.finish();

        this.$ga.event("Quote", "Quote Created - Start", newQuote.quoteId);

        this.$router.push({
          path: `/quote/${newQuote.quoteId}/edit`
        });
      },
      async getInitialFeedItems() {
        let response = await this.$dataService.get("/users/me/feedItems", { expand: "boardItem.user,boardItem.board", desc: true, limit: feedItemLimit });
        if (response.data) {
          this.feedItems = response.data;
        }
      },
      async getMoreFeedItems() {
        if (this.feedItems && this.feedItems.pageNext) {
          this.loadingFeedItems = true;
          let response = await this.$dataService.get(this.feedItems.pageNext);
          if (response.error) {
            this.error = response.error;
            return;
          }
          let newItems = response.data;

          this.feedItems.data = this.feedItems.data.concat(newItems.data);
          this.feedItems.pageNext = newItems.pageNext;
          this.loadingFeedItems = false;
        }
      },
      async copy(boardId) {
        if (this.submitting.copy)
          return;

        this.submitting.copy = true;
        this.$nuxt.$loading.start();
        let response = await this.$dataService.post(`/boards/${this.boardItem.boardId}/boardItems/${this.boardItem.boardItemId}/copy`, null, { to: boardId, expand: "board" });
        this.submitting.copy = false;
        if (response.error) {
          this.modalError = response.error;
          this.$nuxt.$loading.finish();
          return;
        }

        this.$bvModal.hide("modal-board-select");
        let boardItem = response.data;

        this.$nuxt.$loading.finish();
        if (boardItem.boardItemId) {
          let toast = this.$toasted.show("Saved!", {
            theme: "outline",
            position: "bottom-center",
            duration: 5000,
            iconPack: "fontawesome",
            icon: "thumbs-up",
            type: "success",
            keepOnHover: true,
            action: {
              text: 'Take a look',
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
                this.$router.push({ name: "board-boardId-boardSlug", params: { boardSlug: boardItem.board.slug, boardId: boardItem.board.boardId } });
              }
            }
          });
        }
      },
      async createBoard() {

        this.$v.newBoard.$touch();
        if (this.newBoard.private) {
          this.newBoard.hidden = true;
        }

        if (!this.$v.newBoard.$invalid) {
          this.submitting.createBoard = true;
          this.$nuxt.$loading.start();
          let response = await this.$dataService.post("/boards", this.newBoard);
          if (response.error) {
            this.error = response.error;
            this.$nuxt.$loading.finish();
            return;
          }
          let newBoard = response.data;
          this.$ga.event("Board", "Board Created", newBoard.boardId, newBoard.private ? 0 : 1);
          await this.copy(newBoard.boardId);
          this.submitting.createBoard = false;
          this.showCreateBoard = false;
          this.newBoard = { name: null, private: false, hidden: false };
          this.$v.newBoard.$reset();
        }
      },
      async openSave(boardItem) {
        this.boardItem = boardItem;
        this.$bvModal.show("modal-board-select");
        if (!this.boards.data.length) {
          let response = await this.$dataService.get("/boards", { includePreviewImages: true });
          if (response.error) {
            this.error = response.error;
            return;
          }
          this.boards = response.data;
        }
      },
      async getRandomQuote() {
        if (this.randomQuotes.length == 0) {
          // Change the "cb" value when you want to bust browser cache (i.e. if you update the random quotes contents).
          let response = await this.$dataService.get(`${this.$config.BASE_URL}/randomQuotes/randomQuotes.json?cb=5214529`);
          if (response.error) {
            this.error = response.error;
            return;
          }
          this.randomQuotes = response.data;
          this.$ga.event("UIUX", "Get Random Quote", "0");
        }
        let index = Math.floor(Math.random() * (this.randomQuotes.length));
        this.quote = this.randomQuotes[index];
      },
      async readMore(featuredItemId) {
        this.showFeatured.push(featuredItemId);
        this.$ga.event("UIUX", "Show Featured", featuredItemId);
      },
      shouldShowFeatured(featuredItemId) {
        if (this.showFeatured.indexOf(featuredItemId) > -1) {
          return true;
        }
        return false;
      },
      checkLoadFeedItems() {
        window.clearTimeout(isScrolling);
        let vm = this;
        isScrolling = setTimeout(function () {
          var el = document.getElementsByTagName("HTML")[0];
          if (!vm.loadingFeedItems && el && el.scrollHeight - el.scrollTop - el.clientHeight < 600) {
            vm.getMoreFeedItems();
          }
        }, 75);
      },
      getRoutePath() {
        return this.$route.path;
      },
    },
    beforeDestroy: function () {
      window.removeEventListener("scroll", this.checkLoadFeedItems, false);
    },
    computed: {
      ...mapState("self", { self: "self" }),
    },
    directives: {
      resizable: {
        inserted: function (el) {
          el.addEventListener("input", function (e) {
            e.target.style.height = "auto";
            e.target.style.height = (e.target.scrollHeight + 2) + "px";
          });
        }
      }
    },
    validations: {
      quote: {
        quote: {
          required
        }
      },
      newBoard: {
        name: {
          required
        }
      },
    }
  };
</script>
