<template>
  <footer class="footer">
    <div class="social">
      <a href="https://www.pinterest.com/GetQuozio/" target="_blank"><i class="fab fa-pinterest-square fa-2x pinterest m-1"></i></a>
      <a href="https://facebook.com/Quozio" target="_blank"><i class="fab fa-facebook-square fa-2x facebook m-1"></i></a>
      <a href="https://twitter.com/Quozio" target="_blank"><i class="fab fa-twitter-square fa-2x twitter m-1"></i></a>
    </div>
    <div class="text-footer">
      &copy; Copyright {{year}} Quozio.
      <p>
        <a href="/resources/how-to">Help</a> • <a href="/resources/privacy">Privacy</a> • <a href="/resources/terms">Terms</a> • <a href="/contact">Contact Us</a>
      </p>
    </div>
  </footer>
</template>

<style>
  html {
    min-height: 100%;
  }

  .footer {
    text-align: center;
    bottom: 0;
    width: 100%;
    height: 100px;
    background-color: #f8f9fa;
    font-size: 12px;
    box-shadow: 0px 2px 5px 0px rgba(161,161,161,0.5);
  }

    .footer .social {
      margin-top: 15px;
    }
</style>

<script>
  export default {
    data() {
      return {
        year: new Date().getFullYear()
      }
    },
  };
</script>
