<template>
  <nav class="navbar navbar-light bg-light">
    <router-link to="/" class="navbar-brand" style="color: #BB3438">
      <img src="/images/quozio.png" alt="Quozio" width="100" />
    </router-link>
    <div>
      <nuxt-link class="btn btn-primary btn-success btn-pill btn-sm d-none d-sm-inline" :to="{ name: 'index' }" v-if="$route.path != '/'">Make Your Own Quote</nuxt-link>
      <span v-if="!self.userId">
        <nuxt-link class="btn btn-primary btn-sm mr-2 ml-2" to="/sign-in" v-cloak>Sign In</nuxt-link>
        <router-link to="/join" v-cloak>Sign Up</router-link>
      </span>
      <span v-if="self.userId">
        <b-dropdown id="dropdown-right light" right :text="self.name" variant="light" class="m-2">
          <client-only>
            <b-dropdown-item>
              <router-link :to="{ name: 'username', params: { username: self.username } }">My Quotes</router-link>
            </b-dropdown-item>
            <b-dropdown-item>
              <router-link :to="{ name: 'user-designs' }">My Designs</router-link>
            </b-dropdown-item>
            <b-dropdown-item>
              <router-link to="/user/settings">Settings</router-link>
            </b-dropdown-item>
            <b-dropdown-item @click="signOut($cookies)">
              <a href="#">Sign Out</a>
            </b-dropdown-item>
          </client-only>
        </b-dropdown>
      </span>
    </div>
  </nav>
</template>

<script>
  import { mapActions } from "vuex";

  export default {
    props: ["self"],
    methods: {
      ...mapActions({ signOut: "self/signOut" }),
    },
  };
</script>
